import React, { useMemo } from 'react';
import { WorkbenchRoute } from '@tanium/workbench-router';
import { BreadcrumbItem } from '@tanium/react-breadcrumbs';
import {
  ADVISORIES_ROUTE,
  ADVISORY_DRAFT_EDIT_ROUTE,
  ADVISORY_DRAFT_FORM_ROUTE,
  ADVISORY_DRAFT_ROUTE,
  ADVISORY_DRAFTS_ROUTE,
  ADVISORY_QUERY_ROUTE,
  ADVISORY_ROUTE,
  OSS_PENDING_APPROVALS_ROUTE,
  OSS_REQUEST_FORM_ROUTE,
  OSS_REQUEST_ROUTE,
  OSS_REQUESTS_ROUTE,
  OSS_SOFTWARE_ROUTE,
  OSS_SOFTWARES_ROUTE,
  TOKENS_ROUTE,
} from '../Routes';
import { useRbac } from './useRbac';

export interface Page {
  route: WorkbenchRoute;
  breadcrumbs: BreadcrumbItem[] | undefined;
  label: string;
  component: React.LazyExoticComponent<React.FC<unknown>>;
}

export const usePages = (): Page[] => {
  const u = useRbac();
  return useMemo(
    () => [
      {
        route: ADVISORIES_ROUTE,
        breadcrumbs: [],
        label: 'Published Advisories',
        component: React.lazy(() => import('../pages/Advisories/AdvisoriesPage')),
      },
      {
        route: ADVISORY_ROUTE,
        breadcrumbs: [
          {
            href: ADVISORIES_ROUTE.url,
            label: 'Published Advisories',
          },
        ],
        label: 'Advisory',
        component: React.lazy(() => import('../pages/Advisories/AdvisoryPage')),
      },
      {
        route: ADVISORY_DRAFTS_ROUTE,
        breadcrumbs: [],
        label: 'Drafted Advisories',
        component: React.lazy(() => import('../pages/Advisories/AdvisoryDraftsPage')),
      },
      {
        route: ADVISORY_DRAFT_ROUTE,
        breadcrumbs: [
          {
            href: ADVISORY_DRAFTS_ROUTE.url,
            label: 'Drafted Advisories',
          },
        ],
        label: 'Draft',
        component: React.lazy(() => import('../pages/Advisories/AdvisoryDraftPage')),
      },
      {
        route: ADVISORY_DRAFT_FORM_ROUTE,
        breadcrumbs: [
          {
            href: ADVISORY_DRAFTS_ROUTE.url,
            label: 'Drafted Advisories',
          },
        ],
        label: 'New Draft',
        component: React.lazy(() => import('../pages/Advisories/AdvisoryDraftFormPage')),
      },
      {
        route: ADVISORY_DRAFT_EDIT_ROUTE,
        breadcrumbs: [
          {
            href: ADVISORY_DRAFT_ROUTE.url,
            label: 'Drafted Advisories',
          },
        ],
        label: 'Edit Advisory',
        component: React.lazy(() => import('../pages/Advisories/AdvisoryEditPage')),
      },
      {
        route: ADVISORY_QUERY_ROUTE,
        breadcrumbs: [
          {
            href: ADVISORIES_ROUTE.url,
            label: 'Published Advisories',
          },
        ],
        label: 'Applicability',
        component: React.lazy(() => import('../pages/Advisories/AdvisoryQueryPage')),
      },
      ...(u.canListOssSoftware
        ? [
            {
              route: OSS_SOFTWARES_ROUTE,
              breadcrumbs: [],
              label: 'Open Source',
              component: React.lazy(() => import('../pages/Oss/OssSoftwaresPage')),
            },
            {
              route: OSS_SOFTWARE_ROUTE,
              breadcrumbs: [
                {
                  href: OSS_SOFTWARES_ROUTE.url,
                  label: 'Open Source',
                },
              ],
              label: 'Software',
              component: React.lazy(() => import('../pages/Oss/OssSoftwarePage')),
            },
          ]
        : []),
      ...(u.canEditOssRequests
        ? [
            {
              route: OSS_REQUEST_FORM_ROUTE,
              breadcrumbs: [
                {
                  href: OSS_SOFTWARES_ROUTE.url,
                  label: 'Open Source',
                },
              ],
              label: 'Request Form',
              component: React.lazy(() => import('../pages/Oss/OssRequestFormPage')),
            },
            {
              route: OSS_REQUESTS_ROUTE,
              breadcrumbs: [
                {
                  href: OSS_SOFTWARES_ROUTE.url,
                  label: 'Open Source',
                },
              ],
              label: 'My Requests',
              component: React.lazy(() => import('../pages/Oss/MyOssRequestsPage')),
            },
          ]
        : []),
      ...(u.canViewOssRequests
        ? [
            {
              route: OSS_REQUEST_ROUTE,
              breadcrumbs: [
                {
                  href: OSS_SOFTWARES_ROUTE.url,
                  label: 'Open Source',
                },
              ],
              label: 'Request',
              component: React.lazy(() => import('../pages/Oss/OssRequestPage')),
            },
          ]
        : []),
      ...(u.canListOssRequests
        ? [
            {
              route: OSS_PENDING_APPROVALS_ROUTE,
              breadcrumbs: [
                {
                  href: OSS_SOFTWARES_ROUTE.url,
                  label: 'Open Source',
                },
              ],
              label: 'Requests Pending Approval',
              component: React.lazy(() => import('../pages/Oss/OssPendingApprovalsPage')),
            },
          ]
        : []),
      ...(u.canListTokens || u.canListOwnTokens
        ? [
            {
              route: TOKENS_ROUTE,
              breadcrumbs: [],
              label: 'API Tokens',
              component: React.lazy(() => import('../pages/Tokens/TokensPage')),
            },
          ]
        : []),
    ],
    [u],
  );
};

export const NOT_FOUND_PAGE: Page = {
  route: {
    name: 'Not Found',
    key: '404',
    url: '/**',
  },
  breadcrumbs: undefined,
  label: 'Not Found',
  component: React.lazy(() => import('../pages/Errors/RouteNotFoundPage')),
};
